import React, { FormEvent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import FormErrorMessage from 'client/components/FormErrorMessage';
import { validateEmail } from "client/helpers/Form";
import { PENDING, SUBSCRIBED, UNSUBSCRIBED } from "src/effects/SubscriptionStatus/constants";
import { SubscriptionSuccessMsg } from "../SubscriptionSuccessMsg";
import { SubscriptionErrorMsg } from "../SubscriptionErrorMsg";

import { SubscriptionBoxBase, SubscriptionButton, SubscriptionInput, SubscriptionForm } from "./styles";
import { SubscriptionBoxSubTitle, SubscriptionBoxTitle } from "../styles";

interface INewsletterProps {
  readonly status: string;
  readonly onSubscribe: (email: string) => void;
}

export function SubscriptionBox(props: INewsletterProps) {
  const { status, onSubscribe } = props;
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<{ id: string }[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const intl = useIntl();

  const hanldeSubmit = (e: FormEvent) => {
    e.preventDefault();

    setFormSubmitted(true);

    if (validateEmail(email)) {
      setErrors([{ id: "validEmail" }])
      return;
    }
    setErrors([]);
    onSubscribe(email);
  }

  return (
    <SubscriptionBoxBase>
      {(status === SUBSCRIBED || status === PENDING) && (
        <SubscriptionSuccessMsg />
      )}

      {formSubmitted && status === UNSUBSCRIBED && (
        <SubscriptionErrorMsg />
      )}

      {status === UNSUBSCRIBED && (
        <>
          <SubscriptionBoxTitle>
            <FormattedMessage id='subscription.subscribe-to-get-unique-discounts' />
          </SubscriptionBoxTitle>
          <SubscriptionBoxSubTitle>
            <FormattedMessage id='subscription.on-our-newsletter' />
          </SubscriptionBoxSubTitle>
          <SubscriptionForm>
            <SubscriptionInput
              placeholder={intl.formatMessage({ id: 'global.email' })}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <SubscriptionButton size="m" type="button" onClick={hanldeSubmit}>
              <FormattedMessage id='subscription.subscribe-cta' />
            </SubscriptionButton>
          </SubscriptionForm>
          {errors && <FormErrorMessage errors={errors} />}
        </>
      )}
    </SubscriptionBoxBase>
  )
}
