import { updatedMedia } from 'client/styles';
import styled from 'styled-components';

import { PATH_IMAGES_CDN } from 'src/constants/environment';

export const SubscriptionBoxConnectedBase = styled.div`
  width: 100%;
  padding: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${updatedMedia.onlyMobile} {
    background-image: none;
    grid-template-columns: 1fr;
    padding: 0;
    gap: 0;
  }

  ${updatedMedia.onlyTablet} {
    padding: 32px;
    grid-template-columns: 1fr;
    gap: 0;
    background-size: cover;
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_768x400.webp);
  }
  ${updatedMedia.onlyTablet} and (resolution: 2dppx) {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_1536x400.webp);
  }
  ${updatedMedia.onlyTablet} and (resolution: 3dppx) {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_2304x400.webp);
  }

  ${updatedMedia.desktop} {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_1024x400.webp);
  }
  ${updatedMedia.desktop} and (resolution: 2dppx) {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_2048x400.webp);
  }
  ${updatedMedia.desktop} and (resolution: 3dppx) {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_3096x400.webp);
  }

  @media (min-width: 1280px) {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_1920x400.webp);
  }
  @media (min-width: 1280px) and (resolution: 2dppx) {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_3840x400.webp);
  }
  @media (min-width: 1280px) and (resolution: 3dppx) {
    background-image: url(${PATH_IMAGES_CDN}/subscription_box/img-newsletter_5760x400.webp);
  }
`;

export const SubscriptionBoxContainer = styled.div`
  max-width: var(--layout-max-width);
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr;
  gap: 0;

  ${updatedMedia.desktop} {
    grid-template-columns: 1fr 1fr;
    gap: 80px;
  }
`;

export const Spacer = styled.div`
  ${updatedMedia.onlyMobile} {
    display: none;
  }
  ${updatedMedia.onlyMobile} {
    display: none;
  }
`;
