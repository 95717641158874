import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Error } from './styles';
import messages from './messages';

const FormErrorMessage = ({ errors = [] }) => {
  return (
    <>
      {errors.map(error => (
        <Error key={error.id} data-testid={error.id}>
          <FormattedMessage {...messages[error.id]} values={error.values} />
        </Error>
      ))}
    </>
  );
};

FormErrorMessage.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FormErrorMessage;
