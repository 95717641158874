import styled from 'styled-components';

export const SubscriptionBoxTitle = styled.h5`
  color: var(--color--neutral--800);
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-3);
  letter-spacing: var(--font-letterspacing-0);
  line-height: var(--font-lineheight-1);
`;

export const SubscriptionBoxSubTitle = styled.p`
  max-width: 55ch;
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-2);
  letter-spacing: var(--font-letterspacing-0);
  line-height: var(--font-lineheight-3);
  color: var(--color--neutral--800);
`;
