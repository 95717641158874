import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'validation.required',
  },
  minLength: {
    id: 'validation.min',
  },
  maxLength: {
    id: 'validation.max',
  },
  invalid: {
    id: 'validation.invalid',
  },
  fillField: {
    id: 'validation.fill-field',
  },
  validEmail: {
    id: 'login.valid-email',
  },
  pleaseEmail: {
    id: 'subscription.please-enter-your-email',
  },
  pleaseEnterPassword: {
    id: 'subscription.please-enter-password',
  },
  passwordLength: {
    id: 'global.password-length',
  },
  subscriptionRepeatPassword: {
    id: 'subscription.repeat-password',
  },
  legalTerms: {
    id: 'legal.terms-validation',
  },
  passwordDifferent: {
    id: 'login.password-different',
  },
  paxNames: {
    id: 'error.room-names',
  },
});

export default messages;
