import React from 'react'
import { FormattedMessage } from 'react-intl'

import { SubscriptionBoxTitle } from '../styles'

export function SubscriptionErrorMsg() {
  return (
    <SubscriptionBoxTitle>
      <FormattedMessage id='error.default' />
    </SubscriptionBoxTitle>
  )
}
