import { REGEX_EMAIL, REGEX_PHONE, REGEX_PHONE_SYMBOLS, REGEX_WHITESPACES } from './constants';

export function removePhoneSymbols(phone = '') {
  return phone.replace(REGEX_PHONE_SYMBOLS, '').replace(REGEX_WHITESPACES, '');
}

export function cleanPhone(phone = '') {
  const [prefix, ...number] = phone.split(' ');

  if (!number?.length) {
    return removePhoneSymbols(phone);
  }

  return [prefix, removePhoneSymbols(number.join(''))].join('');
}

export function validateName({ name = '', rules }) {
  const errors = [];
  const nameCleaned = name?.trim() || '';

  if (rules.required && nameCleaned.length === 0) {
    errors.push({ id: 'required' });
  }

  if (rules.min && nameCleaned.length < rules.min) {
    errors.push({ id: 'minLength', values: { min: rules.min } });
  }

  if (rules.max && nameCleaned.length > rules.max) {
    errors.push({ id: 'maxLength', values: { max: rules.max } });
  }

  return errors.length ? errors : null;
}

export function validateEmail(value, rules) {
  const errors = [];
  const pattern = new RegExp(REGEX_EMAIL);

  if (rules?.required && typeof value === 'string' && value.length === 0) {
    errors.push({ id: 'required' });
  }

  if (!pattern.test(value)) {
    errors.push({ id: 'pleaseEmail' });
  }

  return errors.length ? errors : null;
}

export function validatePassword(value, rules) {
  const errors = [];

  if (rules.required && value.length === 0) {
    errors.push({ id: 'pleaseEnterPassword' });
  }

  if (rules.min && value.length < rules.min) {
    errors.push({ id: 'passwordLength', values: { min: rules.min } });
  }

  return errors.length ? errors : null;
}

export function validatePhone(value, rules) {
  const cleanValue = cleanPhone(value || '').match(REGEX_PHONE);
  const errors = [];

  if (rules?.required && !cleanValue) {
    errors.push({ id: 'required' });
  }

  return errors.length ? errors : null;
}

export function validateConfirmPassword(current, confirm, rules) {
  const errors = [];

  if (rules.required && current === '') {
    errors.push({ id: 'subscriptionRepeatPassword' });
  }

  if (rules.required && current !== confirm) {
    errors.push({ id: 'passwordDifferent' });
  }

  return errors.length ? errors : null;
}

function validateCheckbox(value, rules) {
  const errors = [];

  if (rules.required && !value) {
    errors.push({ id: 'legalTerms' });
  }

  return errors.length ? errors : null;
}

function validateField(field, value, config) {
  switch (true) {
    case field === 'firstName':
    case field === 'othersFirstName':
    case field === 'lastName':
    case field === 'othersLastName':
    case /rooms[0-9]firstName/.test(field):
    case /rooms[0-9]lastName/.test(field):
      return validateName({ name: value, rules: config });
    case field === 'email':
      return validateEmail(value, config);
    case field === 'privacy':
      return validateCheckbox(value, config);
    default:
      return null;
  }
}

export function validateFields(values, config) {
  const errors = {};

  Object.keys(config).forEach(fieldKey => {
    const error = validateField(fieldKey, values[fieldKey], config[fieldKey]);

    if (typeof values[fieldKey] !== 'undefined' && error) {
      errors[fieldKey] = error;
    }
  });

  return errors;
}
