import React from 'react';
import { SubscriptionBox } from 'src/comps/Molecules/Subscription/SubscriptionBox';
import { useDispatch, useSelector } from 'react-redux';
import { useMarket } from 'src/hooks/useMarket';
import { getSubscriptionStatus } from 'src/effects/SubscriptionStatus/selectors';
import { subscribeRequestAction } from 'src/effects/SubscriptionStatus/actions';

import { Spacer, SubscriptionBoxConnectedBase, SubscriptionBoxContainer } from './styles';

interface INewsletterProps {
  readonly source: string;
}

export function SubscriptionBoxConnected({ source }: INewsletterProps) {
  const dispatch = useDispatch();

  const { market, language } = useMarket();

  const subscriptionStatus = useSelector(getSubscriptionStatus);

  const handleSubscribe = (emailToSubscribe: string) => {
    dispatch(
      subscribeRequestAction({
        hash: emailToSubscribe,
        language,
        market,
        source,
      })
    );
  };

  return (
    <SubscriptionBoxConnectedBase>
      <SubscriptionBoxContainer>
        <Spacer />
        <SubscriptionBox onSubscribe={handleSubscribe} status={subscriptionStatus} />
      </SubscriptionBoxContainer>
    </SubscriptionBoxConnectedBase>
  );
}

export default SubscriptionBoxConnected;
