export const errorsKeys = {
  text: [{ id: 'required' }],
  email: [{ id: 'validEmail' }],
  paxNames: [{ id: 'paxNames' }],
};

export const MIN_LENGTH = 6;

export const REGEX_EMAIL = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
export const REGEX_PHONE = /^\+[0-9]{5,16}$/;
export const REGEX_PHONE_SYMBOLS = /#|-|\(|\)|\./g;
export const REGEX_WHITESPACES = / /g;
