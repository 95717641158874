import styled from 'styled-components';
import { colors } from 'client/styles';

export const Error = styled.span`
  color: ${colors.red2};
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 5px;

  :before {
    content: '·';
    margin-right: 5px;
  }
`;
