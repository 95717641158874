import styled from 'styled-components';

import s4lColors from 'src/styles/colors/colors';
import fonts from 'src/styles/typography/fonts';
import Button from 'src/comps/Atom/Buttons/Button';
import { updatedMedia } from 'client/styles';

export const SubscriptionBoxBase = styled.div`
  padding: 36px;
  border-radius: 24px;
  border: 2px solid ${s4lColors.berlin};
  background: ${s4lColors.tokio};
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${updatedMedia.onlyMobile} {
    border: none;
    border-radius: 0;
    padding: 36px 16px;
  }
`;

export const SubscriptionForm = styled.form`
  border-radius: 48px;
  border: 2px solid ${s4lColors.berlin};
  background: ${s4lColors.berlin};
  display: flex;
  overflow: hidden;
`;
export const SubscriptionInput = styled.input`
  flex: 1;
  border-radius: 50px 0 0 50px;
  border: none;
  padding-left: 24px;

  :hover,
  :focus {
    outline: none;
  }

  ::placeholder {
    font-family: ${fonts.primary};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
`;

export const SubscriptionButton = styled(Button)`
  border-radius: 0 50px 50px 0;
`;
