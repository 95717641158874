import React from 'react'
import { SubscriptionBoxSubTitle, SubscriptionBoxTitle } from '../styles'
import { FormattedMessage } from 'react-intl'
import { DIRECT_OPT_IN_ALLOWED_MARKETS } from 'src/effects/SubscriptionStatus/constants';
import { useMarket } from 'src/hooks/useMarket';

export function SubscriptionSuccessMsg() {
  const { market } = useMarket();

  const subtitleMessageId = DIRECT_OPT_IN_ALLOWED_MARKETS.includes(market) ?
    'subscription.thank-you-for-being-part-of-our-community' :
    'subscription.we-have-sent-you-an-email'

  return (
    <>
      <SubscriptionBoxTitle>
        <FormattedMessage id='global.thank-you' />
      </SubscriptionBoxTitle>
      <SubscriptionBoxSubTitle>
        <FormattedMessage id={subtitleMessageId} />
      </SubscriptionBoxSubTitle>
    </>
  )
}
